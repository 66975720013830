type FlavorType = 'standard';

const flavor: FlavorType = 'standard';

export const environment = {
  production: false,
  flavor: flavor,
  sentryDsn: 'https://681fc57a274e47909618a81a52a573c3@o4504723517800448.ingest.sentry.io/4504723581435904',
  allowSentry: false,
  seatingLayoutHash: '0904829087a2a186869c856e19068a4f',
  i18nHash: 'b6a3a2143cf00c5d3ab17d9f2c082a20',
  defaultLanguage: 'en',
  baseHref: '/',
  allowLogging: true,
  usePaymentEmulator: true,
  useApiEmulator: true,
  canChangeLanguage: true,
  allowPixelTracking: false,
  barionPixelId: null,
  shareUrl: 'https://sandbox.in-time.hu/e',
  apiGatewayUrl: 'https://in-time-api-gateway-9llk7a74.ew.gateway.dev',
  firebase: {
    apiKey: 'AIzaSyAC_MPK5_a7nNqKDolyRwmGAJBgnssp2K4',
    authDomain: 'in-time-fa9b7.firebaseapp.com',
    databaseURL: 'https://in-time-fa9b7.firebaseio.com',
    projectId: 'in-time-fa9b7',
    storageBucket: 'in-time-fa9b7.appspot.com',
    messagingSenderId: '752293627744',
    appId: '1:752293627744:web:53835ceb286d9bf1730005',
    measurementId: 'G-TVFJ99S7JX',
    storageApiUrl: 'https://storage.googleapis.com',
  }
};
